import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  mobile: boolean = false;
  title = 'Did They Work Together?';

  ngOnInit() {
    if(window.innerWidth < 775){
      this.mobile = true;
    }
  }
  
}
